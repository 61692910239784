/* eslint-disable import/no-mutable-exports */
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import authUser from '~/store/authUser'
import auction from '~/store/auction'
import api from '~/store/api'

let authUserStore: authUser
let auctionStore: auction
let apiStore: api

function initialiseStores(store: Store<any>): void {
  authUserStore = getModule(authUser, store)
  auctionStore = getModule(auction, store)
  apiStore = getModule(api, store)
}

export { initialiseStores, authUserStore, auctionStore, apiStore }
