import { Middleware } from '@nuxt/types'
// Record<string, any>)
const myMiddleware: Middleware = (context) => {
  // @ts-ignore
  if (context.ssrContext) {
    context.redirect('/')
  }
}

export default myMiddleware
