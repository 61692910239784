import { Plugin } from '@nuxt/types'
import { authUserStore } from '~/store'

const auth: Plugin = async ({ app }) => {
  console.log('__INIT__')
  if (!process.client) return
  await app.router!.afterEach(async () => {
    await authUserStore.getUser()
    await authUserStore.getSessions()
    await authUserStore.getPaymentMethod()
  })
}

export default auth
