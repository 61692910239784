import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
dayjs.locale('ja')

Vue.filter('dayFormat', (value: any) => {
  if (!value) return ''
  return dayjs(value).format('YYYY/MM/DD(ddd) HH:mm')
})

Vue.filter('timeFormat', (value: any) => {
  if (!value) return ''
  return dayjs(value).format('HH:mm')
})
