import { ActionTree, Store } from 'vuex'

import { initialiseStores } from '~/utils/store-accessor'

export const state = () => ({})
export type RootState = ReturnType<typeof state>

const initializer = (store: Store<any>) => initialiseStores(store)
export const plugins = [initializer]

export const actions: ActionTree<any, any> = {
  nuxtServerInit: async () => {
    // await authUserStore.getUser()
  },
}

export * from '~/utils/store-accessor'
