import { $axios } from '~/utils/api'
import { apiStore } from '~/utils/store-accessor'
export default () => {
  // リクエストの共通処理
  $axios.onRequest((config: any) => {
    if (apiStore.csrfToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers['X-CSRF-Token'] = apiStore.csrfToken
      config.withCredentials = true
    }
    return config
  })
  $axios.onResponse(async (response) => {
    const token = await response.headers['x-csrf-token']
    if (token) {
      await apiStore.getCsrfToken(token)
    }
    return response
  })
}
