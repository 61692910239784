















import { Vue } from 'nuxt-property-decorator'

const DefaultHeader = () => import('~/components/layout/Header.vue')
const FooterNav = () => import('~/components/layout/FooterNav.vue')
const SignIn = () => import('~/components/auth/SignIn.vue')
const SignUp = () => import('~/components/auth/SignUp.vue')
const ResetPass = () => import('~/components/auth/ResetPassForm.vue')
const MaxLimitModal = () => import('~/components/search/SearchMaxModal.vue')
const ConfirmModal = () => import('~/components/price/ConfirmModal.vue')
const UserProfileRegistration = () =>
  import('~/components/auth/UserProfileRegistration.vue')

export default Vue.extend({
  components: {
    DefaultHeader,
    FooterNav,
    SignIn,
    SignUp,
    UserProfileRegistration,
    ResetPass,
    ConfirmModal,
    MaxLimitModal,
  },
  data() {
    return {}
  },
  methods: {},
})
