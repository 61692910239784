import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import Vuex from 'vuex'

const store = new Vuex.Store<any>({})

export interface ApiState {
  csrfToken: string
}

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'api',
  store,
  dynamic: true,
})
export default class Api extends VuexModule implements ApiState {
  csrfToken: string = ''

  @Mutation
  setCsrfToken(data: string) {
    this.csrfToken = data
  }

  @Action({ rawError: true, commit: 'setCsrfToken' })
  getCsrfToken(token: string) {
    return token
  }
}
