import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'

Vue.use(InfiniteLoading, {
  props: {
    spinner: 'default',
  },
  system: {
    throttleLimit: 100,
  },
  slots: {
    noMore: '',
    noResults: '',
  },
})
